@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import '~@fortawesome/fontawesome-free/css/all.css';

:root {
  --button-light-background: #fff;
  --button-light-text: #382b22; 
  --button-dark-background: #382b22; 
  --button-dark-text: #fff; 
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in forwards;
  opacity: 0; 
}

.App {
  font-family: 'Courier New', Courier, monospace;
  background-color: #fff0f0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

.light-mode {
  background-color: #f5f5f5;
  color: #282c34;
}

.dark-mode {
  background-color: #282c34;
  color: white;
}

.App-header {
  text-align: center;
  text-justify: auto;
  padding: 20px;
}

button {
  font-family: inherit;
  font-weight: 900;
  font-size: 0.75em;
  border: 3px solid black;
  border-radius: 0.4em;
  box-shadow: 0.1em 0.1em;
  cursor: pointer;
  background-color: #7c8ba9;
  min-width: 11em;
}

button:hover {
  transform: translate(-0.05em, -0.05em);
  box-shadow: 0.15em 0.15em;
}

button:active {
  transform: translate(0.05em, 0.05em);
  box-shadow: 0.05em 0.05em;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  width: 80%;
  padding: 20px 0;
}

.grid-item {
  display: flex;
  justify-content: center;
}

.navbar {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}

.navbar div + div {
  display: flex;
  align-items: center;
}

.navbar-brand {
  font-family: "Bebas Neue", sans-serif;
  font-size: 2em;
}

.switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 62px;
  height: 35px;
  margin-left: 20px;
}

.switch input {
  opacity: 1;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  background: #282c34;
  transition: .4s;
  border-radius: 30px;
  border: 1px solid #ccc;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.9em;
  width: 1.9em;
  border-radius: 16px;
  left: 1.2px;
  top: 0;
  bottom: 0;
  background-color: white;
  box-shadow: 0 2px 5px #999999;
  transition: .4s;
}

input:checked + .slider {
  background-color: #fff;
  border: 1px solid transparent;
}

input:checked + .slider:before {
  transform: translateX(1.5em);
}

main {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  width: 100%;
}

.light-mode .footer {
  background-color: #282c34;
  color: #f5f5f5;
}

.light-mode .footer .footer-section a {
  color: #f5f5f5;
}

.light-mode .footer .footer-section a:hover {
  color: #1b8d74;
}

.dark-mode .footer {
  background-color: #f5f5f5;
  color: #282c34;
}

.dark-mode .footer .footer-section a {
  color: #282c34;
}

.dark-mode .footer .footer-section a:hover {
  color: #4fd1c5; 
}

.footer {
  padding-bottom: 20px;
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: auto;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: auto;
}

.footer-section p, .footer-section a {
  font-size: 20px;
}

.footer-section a {
  text-decoration: underline;
}

.back-to-home {
  min-width: unset !important;
  padding: 0.75em 1.50em 0.75em 1.50em;
}

@media (max-width: 1200px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 900px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {

  .back-to-home {
    padding: 0.50em 1em 0.50em 1em;
  }

  .footer-section {
    text-align: left;
    margin: 0 10px;
  }

}
