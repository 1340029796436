:root {
  --background-color-light: #f5f5f5;
  --background-color-dark: #7c8ba9;
  --button-background-color-light: #7c8ba9;
  --button-background-color-dark: #f5f5f5;
  --button-text-color-light: #282c34;
  --button-text-color-dark: #282c34;
}

.card {
  background: var(--background-color-light);
  color: var(--button-text-color-light);
}

.card-button {
  background-color: var(--button-background-color-light);
  color: var(--button-text-color-light);
}

.light-mode .card {
  background: var(--background-color-dark);
  color: var(--button-text-color-dark);
}

.light-mode .card-button {
  background-color: var(--button-background-color-dark);
  color: var(--button-text-color-dark);
}


.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 3rem;
}

.card {
  display: grid;
  grid-template-rows: auto 1fr auto;
  border-radius: 20px;
  padding: 1.75rem;
  border: 2px solid #282c34;
  transition: 0.2s ease-out;
  max-width: 100%;
}

.card:hover {
  transform: translateY(-5px);
}

.text-body {
  font-size: 1em;
  font-weight: bold;
}


.text-title {
  font-size: 1.2em;
  font-weight: bold;
  color: #282c34;
  margin-top: 0;
}


.card-button {
  font-family: inherit;
  padding: 1em 3em;
  font-weight: 900;
  font-size: 0.75em;
  border: 3px solid black;
  border-radius: 0.4em;
  box-shadow: 0.1em 0.1em;
  cursor: pointer;
  background-color: #7c8ba9;
  color: black;
  justify-self: start;
}

.card-button:hover {
  transform: translate(-0.05em, -0.05em);
  box-shadow: 0.15em 0.15em;
}

.card-button:active {
  transform: translate(0.05em, 0.05em);
  box-shadow: 0.05em 0.05em;
}

a {
  text-decoration: none;
}
